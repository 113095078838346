import { ResellerInterface } from "@/interfaces/ResellerInterface";
import Vue from "vue";
import store from "@/store";

Vue.filter( "numberToBRL", function ( number : number )
{
    return new Intl.NumberFormat( "pt-BR", { style : "currency", currency : "BRL" } ).format( number );
} );

export const Utils = {
    mounted()
    {
        const windowSize = window.innerHeight;

        // min-height
        const heightFullEL = document.getElementsByClassName( "height-full" ) as HTMLCollectionOf<HTMLElement>;
        Vue.lodash.each( heightFullEL, ( el ) =>
        {
            el.style.minHeight = windowSize.toString().concat( "px" );
        } );

        // max-height
        const heightFullMax = document.getElementsByClassName( "height-full-max" ) as HTMLCollectionOf<HTMLElement>;
        Vue.lodash.each( heightFullMax, ( el ) =>
        {
            el.style.maxHeight = windowSize.toString().concat( "px" );
        } );
    },
    data()
    {
        return {
            URL_BASE : process.env.BASE_URL
        };
    },
    methods : {
        replaceByDefaultSmall(e: any) {
            e.target.src = require("@/assets/img/no_photo_small.png");
        },
        replaceByDefault(e: any) {
            e.target.src = require("@/assets/img/no_photo.png");
        },
        replaceByDefaultBig(e: any) {
            e.target.src = require("@/assets/img/no_photo_big.png");
        },
        disableScrollVitrine(disabled: boolean) {
            if (disabled) {
                document.body.style.overflow = "hidden";
            }
            else {
                document.body.style.overflow = "auto";
            }
        },
        getIconBag(MarcaIdo: string) {
            let color;
            switch ( MarcaIdo.toString() ) {
                case "4":
                case "11":
                    color = "red";
                    break;
                case "7":
                    color = "green";
                    break;
                case "3":
                case "black":
                    color = "black";
                    break;
                default:
                    color = "white";
                    break;
            }
            return require( "../assets/img/icon-bag-".concat( color ).concat( ".png" ) );
        },
        getIconShare( color : string )
        {
            return require( "../assets/img/icon-presente-coracao.png" );
        },
        //
        isMobile()
        {
            return screen.width <= 760;
        },
        isDesktop()
        {
            return window.innerWidth > 768;
        },
        isCPFValid( cpf : string ) : boolean
        {
            let numeros,
                digitos,
                soma,
                i,
                resultado,
                digitosIguais;

            digitosIguais = 1;

            cpf = this.getOnlyNumbers( cpf );

            if ( cpf.length < 11 )
            {
                return false;
            }

            for ( i = 0; i < cpf.length - 1; i++ )
            {
                if ( cpf.charAt( i ) != cpf.charAt( i + 1 ) )
                {
                    digitosIguais = 0;
                    break;
                }
            }

            if ( !digitosIguais )
            {
                numeros = cpf.substring( 0, 9 );
                digitos = cpf.substring( 9 );
                soma = 0;

                for ( i = 10; i > 1; i-- )
                {
                    soma += parseInt( numeros.charAt( 10 - i ) ) * i;
                }

                resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

                if ( (resultado).toString() !== digitos.charAt( 0 ) )
                {
                    return false;
                }

                numeros = cpf.substring( 0, 10 );
                soma = 0;

                for ( i = 11; i > 1; i-- )
                {
                    soma += parseInt( numeros.charAt( 11 - i ) ) * i;
                }

                resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

                return (resultado).toString() === digitos.charAt( 1 );
            }
            else
            {
                return false;
            }
        },
        isEmailValid( email : string )
        {
            const emailTest = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return emailTest.test( email );
        },
        goToURL( url : string )
        {
            window.location.href = url;
            window.location.reload();
        },
        getOnlyNumbers( text : string )
        {
            text = text === null || text === undefined ? "" : text;
            const number = text.toString().replace( /\D/g, "" );
            return number;
        },
        sendWhatsappToReseller( reseller : ResellerInterface, msg : string )
        {

            // Apenas número
            const Fone1 = parseInt( this.getOnlyNumbers( reseller.Fone1 ) );
            const Fone2 = parseInt( this.getOnlyNumbers( reseller.Fone2 ) );
            const Fone3 = parseInt( this.getOnlyNumbers( reseller.Fone3 ) );

            // Identificar telefone
            let phone = 0;

            if ( Fone1 > 0 )
            {
                phone = Fone1;
            }
            else if ( Fone2 > 0 )
            {
                phone = Fone2;
            }
            else if ( Fone3 > 0 )
            {
                phone = Fone3;
            }

            if ( phone > 0 )
            {
                // Montar chamada whatsapp
                const whatsappApi = "https://web.whatsapp.com/send?phone=+55";
                let api = whatsappApi.concat( phone.toString() ).concat( "&text=" ).concat( encodeURIComponent( msg ) );

                if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test( navigator.userAgent ) )
                {
                    api = "https://wa.me/55".concat( phone.toString() ).concat( "?text=" ).concat( encodeURIComponent( msg ) );
                }

                window.open( api );
            }

        },
        calculateBadge( reseller : { CodLoja : "" } )
        {
            if ( reseller !== null )
            {
                // Carregar carrinho salvo
                const cartFull = store.getters.cart;
                let quantity = 0;

                // Filtrar pela Vitrine / Loja
                Vue.lodash.filter( cartFull, {
                    storeCode : (reseller.CodLoja).toString()
                } ).forEach( ( product ) =>
                {
                    quantity = quantity + product.quantidade;
                } );

                return quantity;
            }
        },
        getLogo( MarcaIdo : any )
        {
            let logo = "";

            switch ( MarcaIdo ) {
                case 1 :
                    logo = "arezzo.png";
                    break;
                case 2 :
                    logo = "schutz.png";
                    break;
                case 3 :
                    logo = "alexandre_birman.png";
                    break;
                case 4 :
                    logo = "anacapri.png";
                    break;
                case 6 :
                    logo = "fiever-branco.png";
                    break;
                case 7 :
                    logo = "alme.png";
                    break;
                case 11 :
                    logo = "vans.png";
                    break;
                case "zzvendas":
                    logo = "zzvendas.png";
                    break;
                default :
                    logo = "arezzoco.png";
                    break;
            }

            return require( "../assets/logos/".concat( logo ) );
        },
        getMarcaByID( MarcaIdo : any )
        {
            let marca = "";

            switch ( MarcaIdo ) {
                case 1 :
                    marca = "AREZZO";
                    break;
                case 2 :
                    marca = "SCHUTZ";
                    break;
                case 3 :
                    marca = "ALEXANDRE BIRMAN";
                    break;
                case 4 :
                    marca = "ANACAPRI";
                    break;
                case 6 :
                    marca = "FIEVER";
                    break;
                case 7 :
                    marca = "ALME";
                    break;
                case 11 :
                    marca = "VANS";
                    break;
                default :
                    marca = "nao identificado ".concat(MarcaIdo);
                    break;
            }

            return marca;
        },
        isMixedSizes<bool>(numero: any) {
            return numero % 1 !== 0;
        },
        validMixedSizes<bool>(tamanhos: any) {
            if (Vue.lodash.isEmpty(tamanhos)) {
                return true;
            }
            else {
                const mixedSizes = Vue.lodash.filter(tamanhos, tamanho => {
                    return tamanho % 1 !== 0;
                });
                return mixedSizes.length > 0;
            }
        }
    },
    filters : {
        resizeImage : function ( url : string )
        {
            if ( url === null )
            {
                url = "";
            }
            return url.replace( "1024x768", "200x100" );
        }
    }
};
